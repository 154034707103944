/**
 * Created by 芋道源码
 *
 * 枚举类
 */

/**
 * 全局通用状态枚举
 */
export const CommonStatusEnum = {
  ENABLE: 0, // 开启
  DISABLE: 1 // 禁用
}

/**
 * 用户的社交平台的类型枚举
 */
export const SystemUserSocialTypeEnum = {
  DINGTALK: {
    title: "钉钉",
    type: 20,
    source: "dingtalk",
    img: "https://s1.ax1x.com/2022/05/22/OzMDRs.png",
  },
  WECHAT_ENTERPRISE: {
    title: "企业微信",
    type: 30,
    source: "wechat_enterprise",
    img: "https://s1.ax1x.com/2022/05/22/OzMrzn.png",
  }
}

/**
 * 支付渠道枚举
 */
export const PayChannelEnum = {
  WX_PUB: {
    "code": "wx_pub",
    "name": "微信 JSAPI 支付",
  },
  WX_LITE: {
    "code": "wx_lite",
    "name": "微信小程序支付"
  },
  WX_APP: {
    "code": "wx_app",
    "name": "微信 APP 支付"
  },
  ALIPAY_PC: {
    "code": "alipay_pc",
    "name": "支付宝 PC 网站支付"
  },
  ALIPAY_WAP: {
    "code": "alipay_wap",
    "name": "支付宝 WAP 网站支付"
  },
  ALIPAY_APP: {
    "code": "alipay_app",
    "name": "支付宝 APP 支付"
  },
  ALIPAY_QR: {
    "code": "alipay_qr",
    "name": "支付宝扫码支付"
  },
}

export const TradeCategoryEnum = {
  STOCK: {
    code : "stock",
    name : "股票"
  },
  STOCK_INDUSTRY: {
    code : "industry",
    name : "股票行业"
  },
  INDEX: {
    code : "index",
    name : "指数"
  },
  FUND: {
    code : "fund",
    name : "基金"
  },
  FOREIGN: {
    code: "foreign",
    name: "货币对",
  },
  BOND: {
    code : "bond",
    name : "债券"
  },
  FUTURES: {
    code : "futures",
    name : "期货"
  },
  OPTION: {
    code : "option",
    name : "期权"
  }
}

export const FundCategoryEnum = {
  STOCK: {
    code : "MIXED",
    name : "混合型"
  },
  STOCK_INDUSTRY: {
    code : "STOCK",
    name : "股票型"
  },
  INDEX: {
    code : "BOND",
    name : "债券型"
  },
  FUND: {
    code : "INDEX",
    name : "指数型"
  },
  BOND: {
    code : "QDII",
    name : "QDII"
  },
  FUTURES: {
    code : "FOF",
    name : "FOF"
  },
  OPTION: {
    code : "OTHER",
    name : "其他"
  }
}
