import {decrypt, encrypt} from "@/utils/jsencrypt";

const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'
const InviteCode = "InviteCode"

// ========== Token 相关 ==========

export function getAccessToken() {
  return localStorage.getItem(AccessTokenKey)
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey)
}

export function setToken(token) {
  localStorage.setItem(AccessTokenKey, token.accessToken)
  localStorage.setItem(RefreshTokenKey, token.refreshToken)
}

export function removeToken() {
  localStorage.removeItem(AccessTokenKey)
  localStorage.removeItem(RefreshTokenKey)
}

export function setInviteCode(inviteCode) {
  localStorage.setItem(InviteCode, inviteCode)
}

export function getInviteCode() {
  return localStorage.getItem(InviteCode)
}
